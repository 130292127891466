import homeIcon from "../../assets/svg/layout/home.svg";
import accountStatementIcon from "../../assets/svg/layout/account-statement.svg";
import beneficiaryIcon from "../../assets/svg/layout/beneficiary.svg";
import cardIcon from "../../assets/svg/layout/card.svg";
import moreIcon from "../../assets/svg/layout/more.svg";

import sendMoneyIcon from "../../assets/svg/layout/send-money.svg";
import teamIcon from "../../assets/svg/layout/team.svg";
import tag from "../../assets/icons/tag.svg";
import question from "../../assets/icons/question.svg";
import save from "../../assets/icons/save.svg";
import user from "../../assets/images/community/profile-community.png";
import settingsIcon from "../../assets/svg/layout/settings.svg";
import withdrawIcon from "../../assets/svg/layout/withdraw.svg";

import { PlusOutlined, RightOutlined } from "@ant-design/icons";

export const SIDEBARIMAGES = {
  homeIcon,
  accountStatementIcon,
  beneficiaryIcon,
  cardIcon,
  moreIcon,
  sendMoneyIcon,
  teamIcon,
  settingsIcon,
  withdrawIcon,
  tag,
  question,
  save,
  user,
};

export const SIDEBARICONS = {
  PlusOutlined,
  RightOutlined,
};

import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/api-endpoints-constant";
import {
  addSendMoneyThunkFulfilledReducer,
  addSendMoneyThunkPendingReducer,
  addSendMoneyThunkRejectedReducer,
  clearAccountDetailsReducer,
  clearBulkPaymentDeleteEntryReducer,
  clearBulkPaymentEditFormReducer,
  deleteSendMoneyThunkFulfilledReducer,
  deleteSendMoneyThunkPendingReducer,
  deleteSendMoneyThunkRejectedReducer,
  editSendMoneyThunkFulfilledReducer,
  editSendMoneyThunkPendingReducer,
  editSendMoneyThunkRejectedReducer,
  getSendMoneyThunkFulfilledReducer,
  getSendMoneyThunkPendingReducer,
  getSendMoneyThunkRejectedReducer,
  getSingleSendMoneyThunkFulfilledReducer,
  getSingleSendMoneyThunkPendingReducer,
  getSingleSendMoneyThunkRejectedReducer,
  setAccountDetailsReducer,
  setBulkPaymentDeleteEntryReducer,
  setBulkPaymentEditFormReducer,
} from "./send-money-reducers";
import {
  addSendMoneyThunk,
  deleteSendMoneyThunk,
  editSendMoneyThunk,
  getSendMoneyThunk,
  getSingleSendMoneyThunk,
} from "./send-money-thunk-actions";

const sendMoneyInitialState = {
  postRequestStatus: REQUESTSTATUS.IDLE,
  getRequestStatus: REQUESTSTATUS.IDLE,
  patchRequestStatus: REQUESTSTATUS.IDLE,
  deleteRequestStatus: REQUESTSTATUS.IDLE,
  sendMoneyLists: [] as any,
  meta: {} as any,
  singleSendMoney: {} as any,
  accountDetails: {} as any,
  sendAmount: 0,
  sendMoneyAddDetailsForm: {} as any,
  bulkPaymentEditForm: {} as any,
  deleteBulkEntry: {} as any,
};

export const sendMoneySlice = createSlice({
  name: "sendMoney",
  initialState: sendMoneyInitialState,
  reducers: {
    setAccountDetails: setAccountDetailsReducer,
    clearAccountDetails: clearAccountDetailsReducer,
    setBulkPaymentEditForm: setBulkPaymentEditFormReducer,
    clearBulkPaymentEditForm: clearBulkPaymentEditFormReducer,
    setBulkPaymentDeleteEntry: setBulkPaymentDeleteEntryReducer,
    clearBulkPaymentDeleteEntry: clearBulkPaymentDeleteEntryReducer,
  },
  extraReducers(builder) {
    getSendMoneyThunkPendingReducer(builder, getSendMoneyThunk);
    getSendMoneyThunkFulfilledReducer(builder, getSendMoneyThunk);
    getSendMoneyThunkRejectedReducer(builder, getSendMoneyThunk);

    getSingleSendMoneyThunkPendingReducer(builder, getSingleSendMoneyThunk);
    getSingleSendMoneyThunkFulfilledReducer(builder, getSingleSendMoneyThunk);
    getSingleSendMoneyThunkRejectedReducer(builder, getSingleSendMoneyThunk);

    addSendMoneyThunkPendingReducer(builder, addSendMoneyThunk);
    addSendMoneyThunkFulfilledReducer(builder, addSendMoneyThunk);
    addSendMoneyThunkRejectedReducer(builder, addSendMoneyThunk);

    editSendMoneyThunkPendingReducer(builder, editSendMoneyThunk);
    editSendMoneyThunkFulfilledReducer(builder, editSendMoneyThunk);
    editSendMoneyThunkRejectedReducer(builder, editSendMoneyThunk);

    deleteSendMoneyThunkPendingReducer(builder, deleteSendMoneyThunk);
    deleteSendMoneyThunkFulfilledReducer(builder, deleteSendMoneyThunk);
    deleteSendMoneyThunkRejectedReducer(builder, deleteSendMoneyThunk);
  },
});

export const {
  setAccountDetails,
  clearAccountDetails,
  setBulkPaymentEditForm,
  clearBulkPaymentEditForm,
  setBulkPaymentDeleteEntry,
  clearBulkPaymentDeleteEntry,
} = sendMoneySlice.actions;

export default sendMoneySlice.reducer;

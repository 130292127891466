import { DynamicType } from "../../../templates/dynamic-type/dynamic-type";
import { BusinessDataFunction } from "./careers-data";
import { Col, Row } from "antd";
import manageTeams from "../../../assets/sales-site/personal/biz3.png";

export const Careers = () => {
  const BusinessData = BusinessDataFunction();

  return (
    <div>
      {/* <h1 className="font-extrabold text-transparent text-6xl bg-clip-text bg-gradient-to-r from-[#007BFF] to-[#00D4FF] text-class text-center">
        Join Our Team
      </h1> */}
      <Row className="items-center flex py-8 bg-white">
        <Col xs={1} lg={4}></Col>
        <Col xs={11} lg={8}>
          {/* <h2 style={{ color: "#007BFF", fontSize: "18px" }}>Career</h2> */}
          <h1>Creating an inclusive & diverse environment for your success</h1>
          <p
            style={{ lineHeight: "1.5rem", color: "#7B6A83", fontSize: "18px" }}
          >
            Get a chance to work with a global network of 800+ members, with
            offices in Malaysia, USA, Australia, Germany, and Pakistan. Using
            cutting-edge technologies.
          </p>
        </Col>
        <Col xs={11} lg={10}>
          <div className="mx-auto max-w-sm text-center">
            <img src={manageTeams} alt="teams" className="w-full" />
          </div>
        </Col>
        <Col xs={1} lg={2}></Col>
      </Row>
      <Row className="items-center py-8 flex bg-white ">
        <Col xs={1} lg={4}></Col>
        <Col xs={22} lg={16}>
          <div className="text-center">
            <h3 style={{ fontSize: "18px" }}>COME JOIN US</h3>
            <h1 style={{ fontWeight: 700 }}>Career Opening</h1>
            <div style={{ marginInline: "25%" }}>
              <p
                style={{
                  lineHeight: "1.5rem",
                  color: "#7B6A83",
                  fontSize: "18px",
                }}
              >
                We're always looking for creative, talented self-starters to
                join the Currencygram family. Check out our open roles below
                and fill out an application.
              </p>
            </div>
          </div>
          <div style={{ height: "70vh", background: "#fff" }}>
            <iframe
              src="https://recruiting-stg.personnellibrary.co.uk/job-boards?boardName=currencyBoard-1716270301334"
              width="100%"
              height="100%"
              no-border
              title="Jobs Widget"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </Col>
        <Col xs={1} lg={4}></Col>
      </Row>
    </div>
  );
};

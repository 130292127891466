import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/api-endpoints-constant";
import { referenceDataThunk } from "./reference-data-thunk-actions";
import {
  referenceDataThunkFulfilledReducer,
  referenceDataThunkPendingReducer,
  referenceDataThunkRejectedReducer,
  setReferenceDataReducer,
} from "./reference-data-reducer";

const referenceDataInitialState: any = {
  mortgage: {} as any,
  property: {} as any,
  otherData: [] as any,
  status: REQUESTSTATUS.IDLE,
};

export const referenceDataSlice = createSlice({
  name: "referenceData",
  initialState: referenceDataInitialState,
  reducers: {
    setReferenceData: setReferenceDataReducer,
  },
  extraReducers(builder) {
    referenceDataThunkPendingReducer(builder, referenceDataThunk);
    referenceDataThunkFulfilledReducer(builder, referenceDataThunk);
    referenceDataThunkRejectedReducer(builder, referenceDataThunk);
  },
});

export const { setReferenceData } = referenceDataSlice.actions;

export default referenceDataSlice.reducer;

export const disableBlurOnMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
};

export const onDrop = (e: React.DragEvent<HTMLLabelElement>, { setDragOver, setFileDropError, setFile, acceptedFileType }: any) => {
  e.preventDefault();

  setDragOver(false);

  const selectedFile = e?.dataTransfer?.files[0];

  if (selectedFile.type.split("/")[0] !== acceptedFileType) {
    return setFileDropError("Please provide an image file to upload!");
  }

  setFile(selectedFile);
};


export const setFiles = (e: any, { acceptedFileType, setFileDropError }: any) => {
  const selectedFile = e?.dataTransfer?.files[0];

  if (selectedFile.type.split("/")[0] !== acceptedFileType) {
    return setFileDropError("Please provide an image file to upload!");
  }

}


export const getDataFromLocalStorage = (storageKey: any) => {
  const data = JSON.parse(localStorage.getItem(storageKey) as string) || null
  return data;
}

export const saveDataToLocalStorage = (storageKey: any, dataToSave: any) => {
  const data = localStorage.setItem(storageKey, JSON.stringify(dataToSave))
  return data;
}

export const removeDataFromLocalStorage = (storageKey: any) => {
  const data = localStorage.removeItem(storageKey)
  return data;
}

export const getDataFromSessionStorage = (storageKey: any) => {
  const data = JSON.parse(sessionStorage.getItem(storageKey) as string) || null
  return data;
}

export const saveDataToSessionStorage = (storageKey: any, dataToSave: any) => {
  const data = sessionStorage.setItem(storageKey, JSON.stringify(dataToSave))
  return data;
}

export const removeDataFromSessionStorage = (storageKey: any) => {
  const data = sessionStorage.removeItem(storageKey)
  return data;
}
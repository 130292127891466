export const HOMEPATH = "/home";
export const PERSONALPATH = "/personal";
export const STUDENTPATH = "/stduent";
export const BUSINESSPATH = "/business";
export const CAREERSPATH = "/careers";
export const CONTACTUSPATH = "/contact-us";
export const SHOWINTERESTPATH = "/show-interest";
export const BLOGPATH = "/blog";
export const RECEIVEGIFTPATH = "/receive-gift";
export const RECEIVELINKPATH = "/receive-link";
export const COMMUNITYPATH = "/community";
export const COMMUNITYPARAM = "community";
export const COMMUNITYTOPICSQUESTIONSPATH = ":topics";
export const COMMUNITYMYQUESTIONPATH = "my-question";
export const COMMUNITYSAVEQUESTIONPATH = "save-question";
export const COMMUNITYQUESTIONSDETAILPATH = "questions/:id";
export const COMMUNITYQUESTIONSPARAM = "questions";
export const UNDERCONSTRUCTIONPATH = "/under-construction";
export const LEGALPATH = "/legal";
export const PRIVACYNOTICEPATH = '/privacy-policy';
export const COOKIEPOLICYPATH = '/cookie-policy';
export const TERMSANDCONDITIONSPATH = '/terms-and-conditions';
export const TERMSOFUSEPATH = '/terms-of-use';
export const PRIVACYNOTICEEPATH = '/privacy-notice';
export const SaleHomePath = '/sale-home';

import axios from "axios";
import { BASEURLBACKEND } from "../constants/api-endpoints-constant";

export const axiosInstance = axios.create({
  // baseURL: 'http://localhost:5000/api/'
  // baseURL: `https://gateway-dev.sharemydine.co.uk/`
  baseURL: BASEURLBACKEND,
});
export const axiosRefresh = axios.create({
  // baseURL: 'http://localhost:5000/api/'
  // baseURL: `https://gateway-dev.sharemydine.co.uk/`
  baseURL: BASEURLBACKEND,
});

export const getDataAPI = async (url: string, axiosRef: any, params?: any) => {
  const response = await axiosRef.get(`${url}`, { params: params });
  return response;
};

export const postDataAPI = async (url: string, axiosRef: any, body?: any, params?: any) => {
  const response = await axiosRef.post(`${url}`, body, { params: params });
  return response;
};

export const putDataAPI = async (url: string, axiosRef: any, body?: any, params?: any) => {
  const response = await axiosRef.put(`${url}`, body, { params: params });
  return response;
};

export const patchDataAPI = async (
  url: string,
  axiosRef: any,
  body?: any,
  params?: any,
  contentType?: any
) => {
  const response = await axiosRef.patch(`${url}`, body, { params: params });
  return response;
};

export const deleteDataAPI = async (url: string, axiosRef: any, body?: any, params?: any) => {
  const response = await axiosRef.delete(`${url}`, { data: body });
  return response;
};

import { lazy } from "react";
import {
  EMPTYPATH,
  HOMEPATH,
  SLASHPATH,
} from "../../constants/routes-constant";
import {
  BLOGPATH,
  BUSINESSPATH,
  CAREERSPATH,
  CONTACTUSPATH,
  LEGALPATH,
  PERSONALPATH,
  SHOWINTERESTPATH,
  STUDENTPATH,
  PRIVACYNOTICEPATH,
  COOKIEPOLICYPATH,
  TERMSANDCONDITIONSPATH,
  TERMSOFUSEPATH,
  PRIVACYNOTICEEPATH,
  SaleHomePath,
} from "../../constants/sales-site-routes-constant";
import { SalesSiteRoutesGuard } from "../../guards/sales-site-routes-guard";
import CareersPage from "../../pages/sales-site/careers";
import { PrivateRoutesGuard } from "../../guards/private-routes-guard";
import SaleSitePrivateGuard from "../../guards/sale-site-private-guard";

const IndexPage = lazy(() => import("../../pages/index-page/index"));
const BlogPage = lazy(() => import("../../pages/sales-site/blog"));
const BusinessPage = lazy(() => import("../../pages/sales-site/business"));
const ContactUsPage = lazy(() => import("../../pages/sales-site/contact-us"));
const ShowInterestPage = lazy(() => import("../../pages/sales-site/show-interest"));
const HomePage = lazy(() => import("../../pages/sales-site/home"));
const PersonalPage = lazy(() => import("../../pages/sales-site/personal"));
const StudentPage = lazy(() => import("../../pages/sales-site/student"));
const LegalPage = lazy(() => import("../../pages/sales-site/legal"));
const PrivacyNoticePage = lazy(() => import("../../pages/sales-site/privacy-policy"));
const TermsAndConditionsPage = lazy(() => import("../../pages/sales-site/terms-and-Conditions"));
const TermsOfUsePage = lazy(() => import("../../pages/sales-site/terms-of-use"));
const PolicyPage = lazy(() => import("../../pages/sales-site/privacy-notice"));
const SaleHome = lazy(() => import("../../pages/sales-site/sale-home"));

export const salesSiteRoutes = [
  {
    id: 1,
    pathLink: HOMEPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <HomePage />,
  },
  {
    id: 2,
    pathLink: BLOGPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <IndexPage />,
    child: [
      {
        id: 1,
        pathLink: EMPTYPATH,
        guard: SalesSiteRoutesGuard,
        componentName: <BlogPage />,
      },
    ],
  },
  {
    id: 3,
    pathLink: BUSINESSPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <BusinessPage />,
  },
  {
    id: 4,
    pathLink: CAREERSPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <CareersPage />,
  },
  {
    id: 5,
    pathLink: BLOGPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <BlogPage />,
  },
  {
    id: 6,
    pathLink: CONTACTUSPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <ContactUsPage />,
  },
  {
    id: 7,
    pathLink: SHOWINTERESTPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <ShowInterestPage />,
  },
  {
    id: 8,
    pathLink: PERSONALPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <PersonalPage />,
  },
  {
    id: 9,
    pathLink: STUDENTPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <StudentPage />,
  },
  {
    id: 10,
    pathLink: LEGALPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <LegalPage />,
  },
  {
    id: 11,
    pathLink: LEGALPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <LegalPage />,
  },
  {
    id: 11,
    pathLink: PRIVACYNOTICEPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <PrivacyNoticePage />,
  },
  {
    id: 12,
    pathLink: PRIVACYNOTICEEPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <PolicyPage />,
  },
  {
    id: 13,
    pathLink: TERMSANDCONDITIONSPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <TermsAndConditionsPage />,
  },
  {
    id: 14,
    pathLink: TERMSOFUSEPATH,
    guard: SalesSiteRoutesGuard,
    componentName: <TermsOfUsePage />,
  },
  {
    id: 15,
    pathLink: SaleHomePath,
    guard: SaleSitePrivateGuard,
    componentName: <SaleHome />,
  },
  // {
  //   id: 6,
  //   pathLink: RECEIVEGIFTPATH,
  //   guard: SalesSiteRoutesGuard,
  //   componentName: <ReceiveGiftPage />,
  // },
  // {
  //   id: 7,
  //   pathLink: RECEIVELINKPATH,
  //   guard: SalesSiteRoutesGuard,
  //   componentName: <PersonalPage />,
  // },
  // {
  //   id: 10000,
  //   index: true,
  //   guard:SalesSiteRoutesGuard,
  //   componentName: <Navigate to={HOMEPATH} />,
  // },
];

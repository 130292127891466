import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/api-endpoints-constant";
import {
  addBeneficiaryThunkFulfilledReducer,
  addBeneficiaryThunkPendingReducer,
  addBeneficiaryThunkRejectedReducer,
  clearBulkBeneficiaryDeleteEntryReducer,
  clearBulkBeneficiaryEditFormReducer,
  deleteBeneficiaryThunkFulfilledReducer,
  deleteBeneficiaryThunkPendingReducer,
  deleteBeneficiaryThunkRejectedReducer,
  editBeneficiaryThunkFulfilledReducer,
  editBeneficiaryThunkPendingReducer,
  editBeneficiaryThunkRejectedReducer,
  getBeneficiaryThunkFulfilledReducer,
  getBeneficiaryThunkPendingReducer,
  getBeneficiaryThunkRejectedReducer,
  getSingleBeneficiaryThunkFulfilledReducer,
  getSingleBeneficiaryThunkPendingReducer,
  getSingleBeneficiaryThunkRejectedReducer,
  setBulkBeneficiaryDeleteEntryReducer,
  setBulkBeneficiaryEditFormReducer,
} from "./beneficiary-reducers";
import {
  addBeneficiaryThunk,
  deleteBeneficiaryThunk,
  editBeneficiaryThunk,
  getBeneficiaryThunk,
  getSingleBeneficiaryThunk,
} from "./beneficiary-thunk-actions";

const beneficiaryInitialState = {
  postRequestStatus: REQUESTSTATUS.IDLE,
  getRequestStatus: REQUESTSTATUS.IDLE,
  patchRequestStatus: REQUESTSTATUS.IDLE,
  deleteRequestStatus: REQUESTSTATUS.IDLE,
  beneficiaryLists: [] as any,
  meta: {} as any,
  singleBeneficiary: {} as any,
  bulkCsvAllData: [] as any,
  bulkCsvErrorData: [] as any,
  csvFilename: "",
  bulkBeneficiaryEditForm: {} as any,
  deleteBulkEntry: {} as any,
};

export const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState: beneficiaryInitialState,
  reducers: {
    setBulkBeneficiaryEditForm: setBulkBeneficiaryEditFormReducer,
    clearBulkBeneficiaryEditForm: clearBulkBeneficiaryEditFormReducer,
    setBulkBeneficiaryDeleteEntry: setBulkBeneficiaryDeleteEntryReducer,
    clearBulkBeneficiaryDeleteEntry: clearBulkBeneficiaryDeleteEntryReducer,
  },
  extraReducers(builder) {
    getBeneficiaryThunkPendingReducer(builder, getBeneficiaryThunk);
    getBeneficiaryThunkFulfilledReducer(builder, getBeneficiaryThunk);
    getBeneficiaryThunkRejectedReducer(builder, getBeneficiaryThunk);

    getSingleBeneficiaryThunkPendingReducer(builder, getSingleBeneficiaryThunk);
    getSingleBeneficiaryThunkFulfilledReducer(builder, getSingleBeneficiaryThunk);
    getSingleBeneficiaryThunkRejectedReducer(builder, getSingleBeneficiaryThunk);

    addBeneficiaryThunkPendingReducer(builder, addBeneficiaryThunk);
    addBeneficiaryThunkFulfilledReducer(builder, addBeneficiaryThunk);
    addBeneficiaryThunkRejectedReducer(builder, addBeneficiaryThunk);

    editBeneficiaryThunkPendingReducer(builder, editBeneficiaryThunk);
    editBeneficiaryThunkFulfilledReducer(builder, editBeneficiaryThunk);
    editBeneficiaryThunkRejectedReducer(builder, editBeneficiaryThunk);

    deleteBeneficiaryThunkPendingReducer(builder, deleteBeneficiaryThunk);
    deleteBeneficiaryThunkFulfilledReducer(builder, deleteBeneficiaryThunk);
    deleteBeneficiaryThunkRejectedReducer(builder, deleteBeneficiaryThunk);
  },
});

export const {
  setBulkBeneficiaryEditForm,
  clearBulkBeneficiaryEditForm,
  setBulkBeneficiaryDeleteEntry,
  clearBulkBeneficiaryDeleteEntry,
} = beneficiarySlice.actions;

export default beneficiarySlice.reducer;

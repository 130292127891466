import { useState } from "react";
import { AppBrand } from "../../components/data-display/app-brand/app-brand";
import { HEADERDATA } from "./header-data";
import { NavLink, useNavigate } from "react-router-dom";
import { AppTitle } from "../../components/data-display/app-title/app-title";
import { Col, Row, Drawer } from "antd";
import { AppButton } from "../../components/buttons/app-button/app-button";
import { LOGOCONSTANT } from "../../constants/logo-constant";
import { MenuOutlined } from "@ant-design/icons";
// Import your custom CSS if needed
// import "./styles.css";

export const HeaderSaleSite = () => {
  const navigate = useNavigate();
  const headerAuthButton = HEADERDATA.headerAuthButtonFunction(navigate);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const handleToggle = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const onClose = () => {
    setIsDrawerVisible(false);
  };

  return (
    <Row className="flex items-center justify-between lg:h-20">
      <Col xs={1} lg={1}></Col>
      <Col xs={22} lg={4} className="flex items-center justify-between">
        <AppBrand
          brandLogoContainerClassName="max-w-[200px]"
          imageClassName="w-full"
          routelink="/"
          brandSrc={LOGOCONSTANT.fullLogo}
        />
        <div className="block md:block lg:hidden">
          <MenuOutlined onClick={handleToggle} className="text-xl mr-3" />
        </div>
      </Col>
      <Col
        xs={0}
        lg={12}
        xl={10}
        className="hidden lg:flex items-center justify-evenly text-center"
      >
        {!!HEADERDATA?.headerMenuData?.length &&
          HEADERDATA?.headerMenuData?.map((item) => (
            <NavLink
              to={item.path}
              className="menu-active font-medium text-base"
              key={item.id}
            >
              <AppTitle title={item.title} />
            </NavLink>
          ))}
      </Col>
      <Col xs={22} lg={4} xl={6} className="text-center lg:text-end">
        <div className="hidden lg:block">
          <AppButton buttons={headerAuthButton} btnWrapperClassName="mt-0 lg:mt-0" />
        </div>
      </Col>
      <Col xs={1} lg={1}></Col>

      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        visible={isDrawerVisible}
        className="custom-drawer"
        bodyStyle={{ height: "calc(100% - 55px)", overflow: "auto" }}
      >
        <div style={{ height: "400px", overflow: "auto" }}>
          {!!HEADERDATA?.headerMenuData?.length &&
            HEADERDATA?.headerMenuData?.map((item) => (
              <NavLink
                to={item.path}
                className="menu-active font-medium text-base block mb-3"
                key={item.id}
              >
                <AppTitle title={item.title} />
              </NavLink>
            ))}
          <AppButton buttons={headerAuthButton} btnWrapperClassName="mt-5" />
        </div>
      </Drawer>
    </Row>
  );
};

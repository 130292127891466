import React from 'react'
import { getDataFromLocalStorage } from '../utils/views-utils';
import { HOMEPATH, UNAUTHORIZEDPATH } from '../constants/routes-constant';
import { Navigate } from 'react-router-dom';

const SaleSitePrivateGuard: React.FC<any> = (props: any) => {
    const auth = localStorage.getItem("salesiteToken");
  
   console.log(auth);
   
    // const  { auth } = useAppSelector(state => state)
    // in case of sale site     <Navigate to="/home" /> else    <Navigate to="/authentication/signin" />
//   return(<>
//   {props.children}
//   </>)
    return !auth ? (
      <Navigate to={HOMEPATH} />
    ) : (<> {props.children} </>)
   
  };
  

export default SaleSitePrivateGuard
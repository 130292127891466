import { REQUESTSTATUS } from "../../constants/api-endpoints-constant";

export const setBulkBeneficiaryEditFormReducer = (state: any, { payload }: any) => {
  state.bulkBeneficiaryEditForm = payload.bulkBeneficiaryEditForm;
};

export const clearBulkBeneficiaryEditFormReducer = (state: any) => {
  state.bulkBeneficiaryEditForm = {};
};
export const setBulkBeneficiaryDeleteEntryReducer = (state: any, { payload }: any) => {
  state.deleteBulkEntry = payload.deleteBulkEntry;
};

export const clearBulkBeneficiaryDeleteEntryReducer = (state: any) => {
  state.deleteBulkEntry = {};
};

export const getBeneficiaryThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getBeneficiaryThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getBeneficiaryThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const getSingleBeneficiaryThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getSingleBeneficiaryThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getSingleBeneficiaryThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const addBeneficiaryThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const addBeneficiaryThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.postRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const addBeneficiaryThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const editBeneficiaryThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const editBeneficiaryThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const editBeneficiaryThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const deleteBeneficiaryThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.deleteRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const deleteBeneficiaryThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.deleteRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const deleteBeneficiaryThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.deleteRequestStatus = REQUESTSTATUS.FAILED;
  });
};

import { createSlice } from "@reduxjs/toolkit";
import { REQUESTSTATUS } from "../../constants/api-endpoints-constant";

import {
  clearCurrentStepReducer,
  clearIsProfileModalOpenReducer,
  clearIsQuestionModalOpenReducer,
  clearRestrictGoBackReducer,
  hideBannerReducer,
  setBannerHeadingReducer,
  setCurrentStepReducer,
  setIsProfileModalOpenReducer,
  setIsQuestionModalOpenReducer,
  setPageNameReducer,
  setPageStateReducer,
  setRestrictGoBackReducer,
  showBannerReducer,
} from "./layout-reducers";

const layoutInitialState: any = {
  mobDrawer: false,
  openDrawer: true,
  backgroundImage: "",
  status: REQUESTSTATUS.IDLE,
  getStatus: REQUESTSTATUS.IDLE,
  notifications: [] as any,
  notificationsMeta: {} as any,
  offsetXs: 1,
  offsetMd: 2,
  offsetLg: 2,
  mainXs: 22,
  mainMd: 20,
  mainLg: 20,
  hideSidebar: false,
  infoabout1: "",
  info1: 0,
  infoabout2: "",
  info2: 0,
  showShortInfo: false,
  showBanner: true,
  bannerHeading: "",
  pageName: "",
  currentStep: 0,
  previousStep: 0,
  restrictGoBack: false,
  isQuestionModalOpen: false,
  isProfileModalOpen: false,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState: layoutInitialState,
  reducers: {
    setBackgroundImage(state, { payload }) {
      state.backgroundImage = payload;
    },
    setGrid(state, { payload }) {
      state.offsetXs = payload.offsetXs;
      state.offsetMd = payload.offsetMd;
      state.offsetLg = payload.offsetLg;
      state.mainXs = payload.mainXs;
      state.mainMd = payload.mainMd;
      state.mainLg = payload.mainLg;
    },
    setGridDefault(state) {
      state.offsetXs = 1;
      state.offsetMd = 2;
      state.offsetLg = 2;
      state.mainXs = 22;
      state.mainMd = 20;
      state.mainLg = 20;
    },
    setSidebarHide(state) {
      state.hideSidebar = true;
    },
    setSidebarShow(state) {
      state.hideSidebar = false;
    },
    showInfo(state, { payload }) {
      state.info1 = payload.info1;
      state.info2 = payload.info2;
      state.infoabout2 = payload.infoabout2;
      state.infoabout1 = payload.infoabout1;
      state.showShortInfo = true;
    },
    hideInfo(state) {
      state.info1 = "";
      state.info2 = "";
      state.infoabout2 = "";
      state.infoabout1 = "";
      state.showShortInfo = false;
    },
    hideBanner: hideBannerReducer,
    showBanner: showBannerReducer,
    setBannerHeading: setBannerHeadingReducer,
    setPageName: setPageNameReducer,
    setPageState: setPageStateReducer,
    setCurrentStep: setCurrentStepReducer,
    clearCurrentStep: clearCurrentStepReducer,
    setRestrictGoBack: setRestrictGoBackReducer,
    clearRestrictGoBack: clearRestrictGoBackReducer,
    setIsQuestionModalOpen: setIsQuestionModalOpenReducer,
    clearIsQuestionModalOpen: clearIsQuestionModalOpenReducer,
    setIsProfileModalOpen: setIsProfileModalOpenReducer,
    clearIsProfileModalOpen: clearIsProfileModalOpenReducer,
  },

  extraReducers(builder) {},
});

export const {
  setBackgroundImage,
  setGrid,
  setGridDefault,
  setSidebarHide,
  setSidebarShow,
  showInfo,
  hideInfo,
  hideBanner,
  showBanner,
  setBannerHeading,
  setPageName,
  setPageState,
  setCurrentStep,
  clearCurrentStep,
  setRestrictGoBack,
  clearRestrictGoBack,
  clearIsQuestionModalOpen,
  setIsQuestionModalOpen,
  setIsProfileModalOpen,
  clearIsProfileModalOpen,
} = layoutSlice.actions;

export default layoutSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  MYPERSONALPROFILEADDDOCUMENTAPI,
  MYPERSONALPROFILEDELETEDOCUMENTAPI,
  MYPERSONALPROFILEDETAILSAPI,
  MYPERSONALPROFILEEDITAPI,
  MYPERSONALPROFILEEDITDOCUMENTAPI,
  MYPERSONALPROFILEIMAGECHANGEAPI,
} from "../../constants/api-endpoints-constant";

import { GLOBALSERVERERRORMESSAGE } from "../../constants/api-messages-constant";
import { getDataAPI, patchDataAPI, putDataAPI } from "../../lib/axios";
import { dayjsFormatDate } from "../../lib/dayjs-time-format-lib";
import { showApiAlert } from "../../utils/api-utils";

export const myPersonalDetailsThunk = createAsyncThunk(
  "personal-details/my-personal-details",
  async ({ axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(MYPERSONALPROFILEDETAILSAPI, axiosInstance);
      console.log(response);

      const formatData = response?.data?.data;

      const responseData = {
        message: response?.data?.message,
        name: `${formatData.firstName ?? ""} ${formatData.middleName ?? ""} ${
          formatData.lastName ?? ""
        }`,
        profileImage: formatData.profileImage,
        role: formatData.defaultRole,
        drn: formatData.drn,
        documents: formatData.mydocuments,
        personalProfileInfo: [
          {
            title: "Personal Details",
            data: {
              "Date of Birth": dayjsFormatDate(formatData.dob, "DD/MM/YYYY"),
              Age: formatData.age,
              Gender: formatData.gender,
              "Marital Status": formatData.martialStatus,
              "Employment Status": "employed",
              Occupation: "SE",
              Citizenship: "no",
            },
          },
          {
            title: "Contact Details",
            data: {
              "Post Code": formatData.postCode,
              Email: formatData.email,
              Address: formatData.address,
              "Mobile Phone": formatData.phoneNumber,
            },
          },
        ],
        solicitorpersonalProfileInfo: {
          title: "Solicitor Details",
          data: {
            "SRA Number": formatData.identityNo,
            "Company Name": formatData.companyName,
            "Phone #": formatData.phoneNumber,
            "Email Address": formatData.email,
            "Post Code": formatData.postCode,
            Address: formatData.address,
            "Driving License": formatData.drivingLicenseFile,
          },
        },
      };

      // !!responseData?.message && openAlert(responseData?.message, 'alert-success-message')
      showApiAlert(responseData.message, openAlert, "alert-success-message");
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      openAlert(errorData?.error);
      showApiAlert(errorData?.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsUpdateThunk = createAsyncThunk(
  "personal-details/my-personal-details-update",
  async ({ body, axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await patchDataAPI(MYPERSONALPROFILEEDITAPI, axiosInstance, body);
      console.log(response);

      const formatData = response?.data?.data;

      const responseData = {
        message: response?.data?.message,
        name: `${formatData?.firstName ?? ""} ${formatData?.middleName ?? ""} ${
          formatData?.lastName ?? ""
        }`,
        profileImage: formatData?.profileImage,
        role: formatData?.defaultRole,
        drn: formatData?.drn,
        // documents: formatData.mydocuments,
        personalProfileInfo: [
          {
            title: "Personal Details",
            data: {
              "Date of Birth": dayjsFormatDate(formatData?.dob, "DD/MM/YYYY"),
              Age: formatData?.age,
              Gender: formatData?.gender,
              "Marital Status": formatData?.martialStatus,
              "Employment Status": "employed",
              Occupation: "SE",
              Citizenship: "no",
            },
          },
          {
            title: "Contact Details",
            data: {
              "Post Code": formatData?.postCode,
              Email: formatData?.email,
              Address: formatData?.address,
              "Mobile Phone": formatData?.phoneNumber,
            },
          },
          {
            title: "Solicitor Details",
            data: {
              "SRA Number": formatData.identityNo,
              "Company Name": formatData.companyName,
              "Phone #": formatData.phoneNumber,
              "Email Address": formatData.email,
              "Post Code": formatData.postCode,
              Address: formatData.address,
              "Driving License": formatData.drivingLicenseFile,
            },
          },
        ],
        solicitorpersonalProfileInfo: {
          title: "Solicitor Details",
          data: {
            "SRA Number": formatData?.identityNo,
            "Company Name": formatData?.companyName,
            "Phone #": formatData?.phoneNumber,
            "Email Address": formatData?.email,
            "Post Code": formatData?.postCode,
            Address: formatData?.address,
            "Driving License": formatData?.drivingLicenseFile,
          },
        },
      };
      console.log(responseData);
      // !!responseData?.message && openAlert(responseData?.message, 'alert-success-message')
      showApiAlert(responseData.message, openAlert, "alert-success-message");
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      // openAlert(errorData?.error)
      showApiAlert(errorData?.error, openAlert, "alert-error-message");
      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsChangeProfileImageThunk = createAsyncThunk(
  "personal-details/my-personal-details-change-profile-image",
  async ({ body, axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await putDataAPI(MYPERSONALPROFILEIMAGECHANGEAPI, axiosInstance, body);
      console.log(response);

      const formatData = response?.data?.data;

      const responseData = {
        message: response?.data?.message,
        profileImage: formatData,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsAddDocumentThunk = createAsyncThunk(
  "personal-details/my-personal-details-add-document",
  async ({ body, axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await putDataAPI(MYPERSONALPROFILEADDDOCUMENTAPI, axiosInstance, body);
      console.log(response);

      // const formatData = response?.data?.data; //TODO: will use when api comes

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsEditDocumentThunk = createAsyncThunk(
  "personal-details/my-personal-details-edit-document",
  async ({ body, axiosInstance, openAlert, documentId }: any, { rejectWithValue }) => {
    try {
      const response = await putDataAPI(
        `${MYPERSONALPROFILEEDITDOCUMENTAPI}/${documentId}`,
        axiosInstance,
        body
      );
      console.log(response);

      // const formatData = response?.data?.data; //TODO: will use when api comes

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const myPersonalDetailsDeleteDocumentThunk = createAsyncThunk(
  "personal-details/my-personal-details-delete-document",
  async ({ body, axiosInstance, openAlert, documentId }: any, { rejectWithValue }) => {
    try {
      const response = await putDataAPI(
        `${MYPERSONALPROFILEDELETEDOCUMENTAPI}/${documentId}`,
        axiosInstance,
        body
      );
      console.log(response);

      // const formatData = response?.data?.data; //TODO: will use when api comes

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

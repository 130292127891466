import {
  BENEFICIARYPATH,
  CARDSPATH,
  TEAMSMANAGEMENTPATH,
  MOREPATH,
  TRANSACTIONPATH,
  SENDMONEYPATH,
  DASHBOARDPATH,
  DELEGATEMEMBERPATH,
  WITHDRAWALAMOUNTPATH,
  SLASHPATH,
} from "../../constants/routes-constant";

import {
  COMMUNITYMYQUESTIONPATH,
  COMMUNITYPATH,
  COMMUNITYQUESTIONSPARAM,
  COMMUNITYSAVEQUESTIONPATH,
} from "../../constants/sales-site-routes-constant";

export const SIDEBARIMPORTS = {
  BENEFICIARYPATH,
  CARDSPATH,
  TEAMSMANAGEMENTPATH,
  MOREPATH,
  TRANSACTIONPATH,
  SENDMONEYPATH,
  DASHBOARDPATH,
  DELEGATEMEMBERPATH,
  WITHDRAWALAMOUNTPATH,
  SLASHPATH,
  COMMUNITYMYQUESTIONPATH,
  COMMUNITYPATH,
  COMMUNITYQUESTIONSPARAM,
  COMMUNITYSAVEQUESTIONPATH,
};

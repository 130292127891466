import React from "react";
import { Button } from "antd";
import { HomeOutlined, LogoutOutlined } from "@ant-design/icons";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

function UnderConstructionSection() {
  const navigate = useNavigate();
  const token = !!localStorage.getItem("salesiteToken");
  let location = useLocation();

  if (!token) {
    return (
      <Navigate
        to="/authentication/signin-delegate"
        state={{ from: location }}
        replace
      />
    );
  }

  const handleLogout = () => {
    localStorage.removeItem("salesiteToken");
    navigate("/authentication/signin-delegate", { replace: true });
  };
  return (
    <section className="min-h-screen max-h-screen bg-[#FAFBFD] under-construction-section">
      <div className="flex flex-col gap-7 justify-center items-center min-h-[inherit] w-[90%] m-auto text-center">
        <p className="m-0 text-[2.5rem] md:text-[3rem] lg:text-[3.75rem]">
          Exciting news - our website is getting a makeover! 🛠️
        </p>
        <p className="my-0 mx-auto text-[1rem] sm:text-[1.25rem] md:text[1.5rem] max-w-[95%] md:max-w-[80%]">
          Thanks a bunch for reaching out to us. We've got your info and we're
          thrilled to connect with you soon! Our friendly representative will be
          in touch with all the awesome details and information you need.
        </p>
        <p className="my-0 mx-auto text-[1rem] sm:text-[1.25rem] md:text[1.5rem] max-w-[95%] md:max-w-[80%]">
          Hang tight and talk to you real soon! 🚀
        </p>
        <div className="flex justify-center gap-6">
          <Button
            type="primary"
            className="bg-[#007bff]"
            icon={<HomeOutlined />}
            size="large"
            onClick={() => navigate("/")}
          >
            Home
          </Button>
          <Button
            type="primary"
            className="bg-[#007bff]"
            icon={<LogoutOutlined />}
            size="large"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      </div>
    </section>
  );
}

export default UnderConstructionSection;

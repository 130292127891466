import {
  BUSINESSPROFILEPATH,
  HELPPATH,
  PROFILEMANAGEMENTPATH,
  SIGNINDELEGATEPATH,
  SIGNINPATH,
  SLASHPATH,
} from "../../constants/routes-constant";
import {
  BLOGPATH,
  CAREERSPATH,
  BUSINESSPATH,
  CONTACTUSPATH,
  HOMEPATH,
  PERSONALPATH,
  STUDENTPATH,
  SHOWINTERESTPATH
} from "../../constants/sales-site-routes-constant";

export const HEADERIMPORTS = {
  BUSINESSPROFILEPATH,
  HELPPATH,
  PROFILEMANAGEMENTPATH,
  SIGNINDELEGATEPATH,
  SIGNINPATH,
  SLASHPATH,
  BLOGPATH,
  CAREERSPATH,
  BUSINESSPATH,
  CONTACTUSPATH,
  HOMEPATH,
  SHOWINTERESTPATH,
  PERSONALPATH,
  STUDENTPATH
};

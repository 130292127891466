import { createAsyncThunk } from "@reduxjs/toolkit";
import { REFERENCEDATAAPI } from "../../constants/api-endpoints-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/api-messages-constant";
import { LOCALSTORAGEREFERENCEDATA } from "../../constants/browser-storage-constant";
import { getDataAPI } from "../../lib/axios";
import { saveDataToLocalStorage } from "../../utils/views-utils";

export const referenceDataThunk = createAsyncThunk(
  "referenceData/reference-data",
  async ({ axiosInstance, openAlert }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(REFERENCEDATAAPI, axiosInstance);
      const formatData = response?.data?.data;
      const responseData = {
        mortgage: formatData?.mortgage, // store access token
        property: formatData?.property, // store refresh token
        otherData: formatData,
      };

      saveDataToLocalStorage(LOCALSTORAGEREFERENCEDATA, responseData);
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };
      openAlert?.(errorData?.error);
      return rejectWithValue(errorData);
    }
  }
);

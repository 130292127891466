import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import {
  BLOGPATH,
  CONTACTUSPATH,
  LEGALPATH,
  PRIVACYNOTICEEPATH,
  PRIVACYNOTICEPATH,
  TERMSANDCONDITIONSPATH,
  TERMSOFUSEPATH
} from "../../constants/sales-site-routes-constant";

export const socialIcon = [
  {
    userImage: facebook,
    url:"https://www.facebook.com/currencygram",
  },
  {
    userImage: instagram,
    url:"https://www.instagram.com/currencygram_/",

  },
  {
    userImage: linkedin,
    url:"https://www.linkedin.com/company/currency-gram",

  },
];

export const footerData = [
  // {
  //   title: "Personal Features",
  //   subItems: [
  //     "Company",
  //     "About us",
  //     "Our Team",
  //     "Culture",
  //     "Diversity & inclusion",
  //     "Benefits",
  //   ],
  // },
  {
    title: "Business Features",
    subItems: [
      { label: "Legal", path: LEGALPATH },
      { label: "Privacy policy", path: PRIVACYNOTICEPATH },
      // { label: "Cookie Policy", path: COOKIEPOLICYPATH },
      { label: "Terms & Condition", path: TERMSANDCONDITIONSPATH },
      { label: "Terms of use", path: TERMSOFUSEPATH },
      { label: "privacy Notice", path: PRIVACYNOTICEEPATH },
    ],
  },
  {
    title: "Business Features",
    subItems: [
      // { label: "FAQs", path: "" },
      { label: "Contact Us", path: CONTACTUSPATH },
      { label: "Blogs", path: BLOGPATH },
    ],
  },
  // {
  //   title: "Business Features",
  //   subItems: [
  //     // { label: "Blog", path: "" },
  //     { label: "Newsroom", path: "" },
  //   ],
  // },
  // {
  //   title: "Business Features",
  //   subItems: [
  //     { label: "Career", path: "" },
  //     { label: "Vacancies", path: "" },
  //   ],
  // },
];

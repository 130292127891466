import { REQUESTSTATUS } from "../../constants/api-endpoints-constant";

export const setAccountDetailsReducer = (state: any, { payload }: any) => {
  state.accountDetails = payload.accountDetails;
  state.sendAmount = payload.sendAmount;
  state.sendMoneyAddDetailsForm = payload.sendMoneyAddDetailsForm;
};

export const clearAccountDetailsReducer = (state: any) => {
  state.accountDetails = {};
  state.sendAmount = 0;
  state.sendMoneyAddDetailsForm = {};
};
export const setBulkPaymentEditFormReducer = (state: any, { payload }: any) => {
  state.bulkPaymentEditForm = payload.bulkPaymentEditForm;
};

export const clearBulkPaymentEditFormReducer = (state: any) => {
  state.bulkPaymentEditForm = {};
};

export const setBulkPaymentDeleteEntryReducer = (state: any, { payload }: any) => {
  state.deleteBulkEntry = payload.deleteBulkEntry;
};

export const clearBulkPaymentDeleteEntryReducer = (state: any) => {
  state.deleteBulkEntry = {};
};

export const getSendMoneyThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getSendMoneyThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.getRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getSendMoneyThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.getRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const getSingleSendMoneyThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const getSingleSendMoneyThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const getSingleSendMoneyThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const addSendMoneyThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const addSendMoneyThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.postRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const addSendMoneyThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.postRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const editSendMoneyThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const editSendMoneyThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.patchRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const editSendMoneyThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.patchRequestStatus = REQUESTSTATUS.FAILED;
  });
};

export const deleteSendMoneyThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.deleteRequestStatus = REQUESTSTATUS.PENDING;
  });
};

export const deleteSendMoneyThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.deleteRequestStatus = REQUESTSTATUS.SUCCEEDED;
  });
};

export const deleteSendMoneyThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.deleteRequestStatus = REQUESTSTATUS.FAILED;
  });
};

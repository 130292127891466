import { REQUESTSTATUS } from "../../constants/api-endpoints-constant";

export const referenceDataThunkRejectedReducer = (builder: any, action: any) => {
  builder.addCase(action.rejected, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.FAILED;
    state.error = payload.error;
  });
};

export const referenceDataThunkFulfilledReducer = (builder: any, action: any) => {
  builder.addCase(action.fulfilled, (state: any, { payload }: any) => {
    // console.log(payload)
    state.status = REQUESTSTATUS.SUCCEEDED;
    state.mortgage = payload.mortgage;
    state.property = payload.property;
    state.otherData = payload.otherData;
  });
};
export const referenceDataThunkPendingReducer = (builder: any, action: any) => {
  builder.addCase(action.pending, (state: any, { payload }: any) => {
    state.status = REQUESTSTATUS.PENDING;
  });
};

export const setReferenceDataReducer = (state: any, { payload }: any) => {
  state.status = REQUESTSTATUS.SUCCEEDED;
  state.mortgage = payload.mortgage;
  state.property = payload.property;
  state.otherData = payload.otherDatata;
};

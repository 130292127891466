export const setPageStateReducer = (state: any, { payload }: any) => {
  state.restrictGoBack = payload.restrictGoBack;
};

export const setPageNameReducer = (state: any, { payload }: any) => {
  state.pageName = payload.pageName;
};
export const setBannerHeadingReducer = (state: any, { payload }: any) => {
  state.bannerHeading = payload.heading;
};
export const showBannerReducer = (state: any) => {
  state.showBanner = true;
};
export const hideBannerReducer = (state: any) => {
  state.showBanner = false;
};

export const setCurrentStepReducer = (state: any, { payload }: any) => {
  console.log(payload);
  state.currentStep = payload.currentStep;
  state.previousStep = payload.previousStep;
};
export const clearCurrentStepReducer = (state: any) => {
  state.currentStep = 0;
  state.previousStep = 0;
};

export const setRestrictGoBackReducer = (state: any) => {
  state.restrictGoBack = true;
};
export const clearRestrictGoBackReducer = (state: any) => {
  state.restrictGoBack = false;
};

export const setIsQuestionModalOpenReducer = (state: any) => {
  state.isQuestionModalOpen = true;
};
export const clearIsQuestionModalOpenReducer = (state: any) => {
  state.isQuestionModalOpen = false;
};

export const setIsProfileModalOpenReducer = (state: any) => {
  state.isProfileModalOpen = true;
};
export const clearIsProfileModalOpenReducer = (state: any) => {
  state.isProfileModalOpen = false;
};

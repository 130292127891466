import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEMOAPI } from "../../constants/api-endpoints-constant";
import { GLOBALSERVERERRORMESSAGE } from "../../constants/api-messages-constant";
import { deleteDataAPI, getDataAPI, patchDataAPI, postDataAPI } from "../../lib/axios";
import { showApiAlert } from "../../utils/api-utils";

export const getBeneficiaryThunk = createAsyncThunk(
  "beneficiary/get-beneficiary",
  async ({ axiosInstance, openAlert, navigate, dispatch, params }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(DEMOAPI, axiosInstance, params);
      console.log(response);
      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        beneficiaryLists: formatData.beneficiary_postings,
        meta: formatData.meta,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");

      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const getSingleBeneficiaryThunk = createAsyncThunk(
  "beneficiary/get-single-beneficiary",
  async ({ axiosInstance, openAlert, beneficiaryId, setAddJob }: any, { rejectWithValue }) => {
    try {
      const response = await getDataAPI(`${DEMOAPI}/${beneficiaryId}`, axiosInstance);
      console.log(response);
      const formatData = response?.data?.data;
      const responseData = {
        message: response?.data?.message,
        singleJob: formatData,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddJob?.(true);
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const addBeneficiaryThunk = createAsyncThunk(
  "beneficiary/add-beneficiary",
  async (
    { axiosInstance, openAlert, dispatch, body, setAddJob, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await postDataAPI(DEMOAPI, axiosInstance, body);
      console.log(response);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddJob?.(false);
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(getBeneficiaryThunk({ axiosInstance, openAlert, navigate, dispatch, params }));
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const editBeneficiaryThunk = createAsyncThunk(
  "beneficiary/edit-beneficiary",
  async (
    { axiosInstance, openAlert, dispatch, body, setAddJob, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await patchDataAPI(DEMOAPI, axiosInstance, body);
      console.log(response);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setAddJob?.(false);
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(getBeneficiaryThunk({ axiosInstance, openAlert, navigate, dispatch, params }));
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");

      return rejectWithValue(errorData);
    }
  }
);

export const deleteBeneficiaryThunk = createAsyncThunk(
  "beneficiary/delete-beneficiary",
  async (
    { axiosInstance, openAlert, body, dispatch, setIsDelete, navigate }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDataAPI(DEMOAPI, axiosInstance, body);
      console.log(response);

      const responseData = {
        message: response?.data?.message,
      };

      showApiAlert(responseData.message, openAlert, "alert-success-message");
      setIsDelete?.({ open: false, id: null });
      const params = {
        limit: 10,
        offset: 0,
        search: undefined,
      };
      dispatch(getBeneficiaryThunk({ axiosInstance, openAlert, navigate, dispatch, params }));
      return responseData;
    } catch (error: any) {
      const errorData = {
        error: error?.response?.data?.message || GLOBALSERVERERRORMESSAGE,
      };

      showApiAlert(errorData.error, openAlert, "alert-error-message");
      setIsDelete?.({ open: false, id: null });
      return rejectWithValue(errorData);
    }
  }
);
